.main {
  overflow: hidden;
  margin: 122px 0 0;
  transition: .3s;
  &.is-fixed {
    margin-top: 76px;
  }
  @include mq(sp) {
    margin: 65px 0 0;
  }
}

.article {
  padding: 0 0 20px;
  background: #f0eeec;
}

.contents {
  max-width: 940px;
  margin: 0 auto;
  padding: 0 20px;
}

.sec {
  position: relative;
  max-width: 1100px;
  margin: 0 auto 50px;
  padding: 0 75px 100px;
  background: #fff;
  box-sizing: border-box;
  @include mq(sp) {
    margin: 0 20px 50px;
    padding: 0 20px 30px;
  }
  &:last-child {
    margin-bottom: 90px;
    @include mq(sp) {
      margin-bottom: 50px;
    }
  }
  &-ttl {
    position: relative;
    margin: 0 0 80px;
    padding: 70px 0 55px;
    border-bottom: 1px solid #e6e6e6;
    /* color: $primary; */
    color: $secondary;
    font-size: 24px;
    font-weight: normal;
    @include mq(sp) {
      margin: 0 0 45px;
      padding: 35px 0 25px;
      font-size: 17px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 110px;
      height: 6px;
      /* background: $primary; */
      background: $secondary;
      @include mq(sp) {
        width: 55px;
        height: 3px;
      }
    }
  }
  &-inner {
    padding: 0 40px;
    @include mq(sp) {
      padding: 0;
    }
  }
}

.lead {
  margin: -110px 0 60px;
  padding: 0 20px;
  text-align: center;
  @include mq(sp) {
    margin: -25px 0 40px;
    text-align: left;
  }
}
