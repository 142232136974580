@charset "utf-8";

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

@import "tools/mixins";
@import "tools/var";
@import "tools/normalize";
@import "tools/remodal";
@import "tools/remodal-default-theme";

@import "layout/base";
@import "layout/header";
@import "layout/footer";
@import "layout/main";

@import "module/table";
@import "module/buttons";
@import "module/nav";
@import "module/heading";
@import "module/list";
@import "module/utilities";