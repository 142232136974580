.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 70px 50px;
  @include mq(sp) {
    justify-content: flex-start;
    padding: 40px 20px;
  }
  &-logo {
    width: 236px;
    line-height: 1;
    margin: 0 0 20px;
    @include mq(sp) {
      order: 1;
      width: 216px;
    }
  }
  &-nav {
    display: flex;
    align-items: center;
    list-style: none;
    line-height: 1;
    margin: 0;
    padding: 0;
    @include mq(sp) {
      order: 3;
    }
    &-item {
      position: relative;
      margin: 0 0 0 20px;
      padding: 0 0 0 20px;
      @include mq(sp) {
        margin: 0 0 0 15px;
        padding: 0 0 0 15px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 16px;
        background: $default;
        transform: translateY(-50%);
      }
      a {
        color: $default;
        text-decoration: none;
        &:hover {
          @include mq(pc) {
            text-decoration: underline;
          }
        }
        img {
          width: 28px;
        }
      }
      &-twitter {
        padding-left: 50px;
      }
    }
  }
  &-copyright {
    width: 100%;
    color: $primary;
    font-size: 10px;
    font-family: $font-gt;
    @include mq(sp) {
      order: 2;
      margin: 0 0 40px;
    }
  }
}
