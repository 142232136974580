.page-ttl-01 {
  position: relative;
  line-height: 1;
  margin: 0;
  padding: 90px 20px;
  color: $secondary;
  font-weight: bold;
  text-align: center;
  /* background: $primary; */
  background-image: url(../img/common/bg-gra-top.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  z-index: 0;
  @include mq(sp) {
    padding: 30px 20px 50px;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: -170px;
    left: 0;
    /* background: $primary; */
    background-image: url(../img/common/bg-gra-top.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    z-index: -1;
    @include mq(sp) {
      bottom: -50px;
    }
  }
  span {
    display: block;
    &[lang="en"] {
      font-size: 34px;
      font-family: $font-gt;
      font-weight: 500;
      letter-spacing: .1em;
      @include mq(sp) {
        font-size: 23px;
      }
    }
    &[lang="ja"] {
      margin: 15px 0 0;
      font-size: 14px;
      @include mq(sp) {
        margin: 10px 0 0;
        font-size: 10px;
      }
      &:first-child {
        margin: 0;
        font-size: 36px;
        @include mq(sp) {
          font-size: 21px;
        }
      }
    }
  }
}

.page-ttl-02 {
  margin: 0 0 180px;
  /* background: $primary; */
  background-image: url(../img/common/bg-gra-top.png);
  background-repeat: no-repeat;
  background-size: cover;
  @include mq(sp) {
    margin: 0 0 60px;
  }
  &-inner {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;
    height: 355px;
    max-width: 1200px;
    margin: 0 auto;
    z-index: 0;
    @include mq(sp) {
      height: 240px;
    }
    h1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      width: 475px;
      height: 195px;
      line-height: 1;
      margin: 0;
      padding: 0 0 0 125px;
      /* color: $primary; */
      color: $secondary;
      background: #f0eeec;
      box-sizing: border-box;
      z-index: 0;
      @include mq(sp) {
        width: 250px;
        height: 110px;
        padding: 0 0 0 20px;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -100vw;
        background: #f0eeec;
        z-index: -1;
      }
      span {
        display: block;
        &[lang="en"] {
          line-height: 1.3;
          font-size: 34px;
          font-family: $font-gt;
          font-weight: 500;
          letter-spacing: .1em;
          @include mq(sp) {
            font-size: 23px;
          }
        }
        &[lang="ja"] {
          margin: 10px 0 0;
          font-size: 14px;
          @include mq(sp) {
            margin: 10px 0 0;
            font-size: 10px;
          }
        }
      }
    }
    figure {
      position: absolute;
      top: 0;
      right: -100vw;
      left: 50px;
      margin: 0;
      z-index: -1;
      @include mq(sp) {
        right: 0;
        left: 20px;
      }
      img {
        width: 100%;
        max-width: 1200px;
        height: 355px;
        object-fit: cover;
        @include mq(sp) {
          height: 240px;
        }
      }
    }
  }
}

.ttl-01 {
  margin: 120px 0 50px;
  color: $primary;
  font-size: 24px;
  font-family: $font-gt;
  @include mq(sp) {
    margin: 80px 0 30px;
    font-size: 17px;
  }
  &:before {
    content: "";
    display: block;
    width: 32px;
    height: 6px;
    margin: 0 0 20px;
    background: $primary;
    @include mq(sp) {
      width: 20px;
      height: 3px;
      margin: 0 0 15px;
    }
  }
  &:first-of-type {
    margin-top: 0;
  }
}

.ttl-02 {
  margin: 100px 0 20px;
  padding: 50px 0 0;
  border-top: 1px solid $line;
  color: $primary;
  font-size: 16px;
  @include mq(sp) {
    margin: 45px 0 20px;
    padding: 30px 0 0;
    font-size: 13px;
  }
  .ttl-01 + & {
    margin-top: 0;
  }
}

.ttl-03 {
  margin: 0 0 30px;
  color: $primary;
  font-size: 23px;
  font-weight: normal;
  @include mq(sp) {
    margin: 0 0 20px;
    font-size: 16px;
  }
}
