@font-face{
  font-family: 'GT-Walsheim-Pro';
  src: url("/assets/fonts/GT-Walsheim-Pro-Light.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face{
  font-family: 'GT-Walsheim-Pro';
  src: url("/assets/fonts/GT-Walsheim-Pro-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face{
  font-family: 'GT-Walsheim-Pro';
  src: url("/assets/fonts/GT-Walsheim-Pro-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

html {
  scroll-padding-top: 50px;
  @include mq(sp) {
    scroll-padding-top: 20px;
  }
}

body {
  line-height: 2.2;
  overflow-x: hidden;
  color: $default;
  font-size: 13px;
  font-family: "Yu Gothic", YuGothic, sans-serif;
  letter-spacing: .02em;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  @include mq(sp) {
    font-size: 12px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.7;
}

p,
ul,
ol,
table {
  margin-bottom: 2em;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

th,
td {
  word-break: break-all;
}

ul {
  padding-left: 2em;
}

ol {
  padding-left: 2em;
}

a {
  color: $primary;
  text-decoration: underline;
  transition: .3s;
  &:hover {
    @include mq(pc) {
      text-decoration: none;
    }
  }
}

figure {
  margin-right: 0;
  margin-bottom: 2em;
  margin-left: 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

svg {
  vertical-align: top;
}

iframe {
  vertical-align: top;
}

video {
  vertical-align: top

}

.sp-link {
  &:hover {
    text-decoration: none;
    cursor: text;
  }
}

.over {
  &:hover {
    text-decoration: none;
    @include mq(pc) {
      opacity: .8;
    }
  }
}