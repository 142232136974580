.tbl-01 {
  width: 100%;
  border-top: 1px solid $line;
  border-collapse: collapse;
  th {
    padding: 20px 50px 20px 0;
    border-bottom: 1px solid $line;
    font-size: 13px;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    @include mq(sp) {
      display: block;
      padding: 15px 0 5px;
      border: none;
    }
  }
  td {
    padding: 20px 0;
    border-bottom: 1px solid $line;
    font-size: 13px;
    @include mq(sp) {
      display: block;
      padding: 0 0 15px;
    }
  }
}

.tbl-02 {
  width: 100%;
  margin-top: 80px;
  /* border-top: 2px solid $primary;
  border-bottom: 2px solid $primary; */
  border-top: 2px solid $secondary;
  border-bottom: 2px solid $secondary;
  border-collapse: collapse;
  @include mq(sp) {
    margin-top: 50px;
  }
  caption {
    margin: 0 0 10px;
    /* color: $primary; */
    color: $secondary;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    @include mq(sp) {
      margin: 0 0 5px;
      font-size: 13px;
    }
  }
  th {
    padding: 10px;
    border-bottom: 1px solid $line;
    font-weight: normal;
    text-align: left;
    @include mq(sp) {
      display: block;
      padding: 15px 10px 0 10px;
      border-bottom: none;
    }
  }
  td {
    padding: 10px;
    border-bottom: 1px solid $line;
    @include mq(sp) {
      display: block;
      padding: 0 10px 15px;
      #outline & {
        text-align: left !important;
      }
    }
  }
  &-notes {
    margin-top: -15px;
    font-size: 12px;
    text-align: right;
    @include mq(sp) {
      font-size: 9px;
    }
  }
}
