.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  /* background: $primary; */
  background-image: url(../img/common/bg-gra-top.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  z-index: 10000;
  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 122px;
    padding: 0 50px;
    transition: .3s;
    @include mq(sp) {
      height: 65px;
      padding: 0 20px;
    }
    .is-fixed & {
      height: 76px;
      padding-top: 20px;
      @include mq(sp) {
        height: 65px;
        padding-top: 0;
      }
    }
  }
  &-logo {
    width: 174px;
    overflow: hidden;
    line-height: 1;
    margin: 0;
    font-size: inherit;
    @include mq(sp) {
      width: 120px;
    }
    .is-fixed & {
      @include mq(pc) {
        width: 34px;
      }
      img {
        max-width: inherit;
        width: 174px;
        @include mq(sp) {
          width: 120px;
        }
      }
    }
  }
  &-nav {
    display: flex;
    align-items: flex-end;
    gap: 25px;
    position: relative;
    height: 122px;
    transition: .3s;
    @include mq(sp) {
      display: none;
      position: absolute;
      top: 65px;
      right: 0;
      left: 0;
      height: calc(100vh - 65px);
      overflow: auto;
      padding: 0 20px 80px;
      background: $primary;
      background-image: url(../img/common/bg-gra-top.png);
      box-sizing: border-box;
      transition: none;
    }
    .is-fixed & {
      height: 76px;
      @include mq(sp) {
        height: calc(100vh - 65px);
      }
    }
    &-btn {
      display: none;
      @include mq(sp) {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        width: 65px;
        height: 65px;
        cursor: pointer;
        transition: .5s;
        z-index: 200;
        span {
          display: block;
          position: absolute;
          left: 14px;
          width: 37px;
          height: 2px;
          background: #fff;
          transition: .5s;
          &:nth-child(1) {
            top: 24px;
          }
          &:nth-child(2) {
            bottom: 24px;
          }
        }
        &.is-open {
          span {
            &:nth-child(1) {
              transform: translateY(7.5px) rotate(-30deg);
            }
            &:nth-child(2) {
              transform: translateY(-7.5px) rotate(30deg);
            }
          }
        }
      }
    }
    &-main {
      display: flex;
      /* position: absolute;
      top: 46px;
      right: 0; */
      list-style: none;
      margin: 0;
      padding: 0;
      transition: .3s;
      @include mq(sp) {
        display: block;
        position: static;
        margin: 0 0 40px;
      }
      &-item {
        position: relative;
        @include mq(sp) {
          border-bottom: 1px solid #866fa7;
        }
        & + & {
          margin-left: 25px;
          @include mq(sp) {
            margin: 0;
          }
        }
        & > a {
          display: flex;
          align-items: flex-start;
          position: relative;
          height: 76px;
          color: #000;
          text-decoration: none;
          @include mq(sp) {
            display: block;
            height: auto;
            padding: 20px 0;
          }
          &:after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 5px;
            background: #fff;
            background: $secondary;
            opacity: 0;
            transition: .3s;
            @include mq(sp) {
              content: none;
            }
          }
        }
        &:hover {
          & > a:after {
            @include mq(pc) {
              opacity: 1;
            }
          }
        }
        @include mq(sp) {
          &.has-sub {
            & > a {
              &:before {
                content: "＋";
                position: absolute;
                top: 50%;
                right: 0;
                font-size: 24px;
                transform: translateY(-50%);
              }
            }
            &.is-open {
              & > a {
                &:before {
                  content: "－";
                }
              }
            }
          }
        }
      }
      .is-fixed & {
        /* top: 50%;
        right: 440px;
        transform: translateY(-50%); */
        @include mq(sp) {
          transform: none;
        }
        a{
          height: 50px;
          @include mq(sp) {
            height: inherit;
          }
        }
      }
    }
    &-low {
      display: none;
      list-style: none;
      position: absolute;
      top: 76px;
      left: 50%;
      width: 260px;
      margin: 0;
      padding: 40px;
      background: #fff;
      transform: translateX(-50%);
      @include mq(sp) {
        position: static;
        width: auto;
        padding: 10px 0 30px;
        transform: none;
        background: none;
      }
      &-item {
        margin: 0 0 10px;
        &:last-child {
          margin: 0;
        }
        a {
          display: block;
          color: #1a1a23;
          text-align: center;
          text-decoration: none;
          @include mq(sp) {
            color: #fff;
            text-align: left;
          }
          &:hover {
            @include mq(pc) {
              span {
                border-color: $secondary;
                /* border-color: $primary; */
              }
            }
          }
          span {
            display: inline-block;
            border-bottom: 2px solid #fff;
            transition: .3s;
            @include mq(sp) {
              border: none;
            }
          }
        }
      }
    }
    &-sub {
      display: flex;
      /* position: absolute;
      top: 25px;
      right: 0; */
      list-style: none;
      margin: 0;
      padding: 0;
      transition: .3s;
      position: relative;
      @include mq(sp) {
        /* flex-wrap: wrap; */
        position: static;
        margin-top: 20px;
      }
      &-item {
        @include mq(sp) {
          /* flex: 1; */
        }
        & + & {
          margin-left: 5px;
          @include mq(sp) {
            margin-left: 10px;
          }
        }
        &.is-career {
          position: relative;
          @include mq(sp) {
            width: 50%;
          }
          .header-nav-sub-item__link {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 30px;
            margin-bottom: calc(76px - 32px);
            color: #fff;
            background: $secondary;
            border: 1px solid $secondary;
            text-decoration: none;
            /* transform: translateY(-150%); */
            @include mq(sp) {
              width: 100%;
              transform: none;
              margin-bottom: 0;
            }
            &:hover {
              @include mq(pc) {
                color: $secondary;
                background: #fff;
              }
            }
          }
        }
      }
      .is-fixed & {
        .is-career{
          @include mq(sp) {
            height: auto;
          }
        }
        .is-career a{
          /* transform: translateY(-75%); */
          margin-bottom: calc(50px - 32px);
          @include mq(sp) {
            transform: none;
            margin-bottom: 0;
          }
        }
      }
      & .header-nav-low {
        display: none;
        list-style: none;
        position: absolute;
        top: 32px;
        left: 50%;
        width: 260px;
        margin: 0;
        padding: 40px;
        background: #fff;
        transform: translateX(-50%);
        text-align: center;
        @include mq(sp) {
          position: static;
          width: auto;
          padding: 10px 0 30px;
          transform: none;
          background: none;
        }
        &-item {
          margin: 0 0 10px;
          &:last-child {
            margin: 0;
          }
          a {
            display: block;
            color: #1a1a23;
            text-align: center;
            text-decoration: none;
            transform: none;
            background-color: none;
            @include mq(sp) {
              color: #fff;
              text-align: left;
            }
            &:hover {
              @include mq(pc) {
                span {
                  border-color: $secondary;
                }
              }
            }
            span {
              display: inline-block;
              border-bottom: 2px solid #fff;
              transition: .3s;
              @include mq(sp) {
                border: none;
              }
            }
          }
        }
      }
    }
  }
  &.is-fixed .header-nav-sub .header-nav-low{
    top: 60px;
    @include mq(sp) {
      top: initial;
    }
  }
}

@include mq(pc) {
  #outline .header-nav-main-item-outline > a:after {
    opacity: 1;
  }
  #feature .header-nav-main-item-feature > a:after {
    opacity: 1;
  }
  #blog .header-nav-main-item-blog > a:after {
    opacity: 1;
  }
  #system-support .header-nav-main-item-system-support > a:after {
    opacity: 1;
  }
  #office .header-nav-main-item-office > a:after {
    opacity: 1;
  }
  #contact .header-nav-main-item-contact > a:after {
    opacity: 1;
  }
}
