.index-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  max-width: 875px;
  margin: 0 auto;
  @include mq(sp) {
    display: block;
  }
  &:after {
    content: "";
    display: block;
    width: 28.68571428571429%;
    @include mq(sp) {
      content: none;
    }
  }
  &-item {
    width: 28.68571428571429%;
    margin: 0 0 100px;
    @include mq(sp) {
      width: auto;
      margin: 0 0 50px !important;
    }
    &:nth-child(3n+1) {
      margin-top: -130px;
    }
    &:nth-child(3n-1) {
      margin-top: -65px;
    }
    &:nth-child(1) {
      margin-top: 0;
    }
    &:nth-child(2) {
      margin-top: 65px;
    }
    &:nth-child(3) {
      margin-top: 130px;
    }
    a {
      display: block;
      color: inherit;
      text-decoration: none;
      &:hover {
        @include mq(pc) {
          .index-nav-img img {
            transform: scale(1.07);
          }
        }
      }
    }
  }
  &-img {
    overflow: hidden;
    margin: 0;
    img {
      width: 100%;
      transition: .3s;
    }
  }
  &-ttl {
    display: flex;
    align-items: center;
    position: relative;
    height: 82px;
    line-height: 1.5;
    margin: -82px 60px 20px 0;
    color: $secondary;
    /* color: $primary; */
    font-size: 16px;
    font-weight: bold;
    background: #f0eeec;
    @include mq(sp) {
      height: 90px;
      margin: -90px 70px 15px 0;
    }
  }
}

.wp-pagenavi {
  display: flex;
  align-items: center;
  line-height: 1;
  margin: 60px 0 0;
  @include mq(sp) {
    justify-content: center;
    margin: 30px 0 0;
  }
  a,
  span {
    display: block;
    margin: 0 25px 0 0;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    font-family: $font-gt;
    @include mq(sp) {
      margin: 0 5px !important;
      font-size: inherit;
    }
    &:last-child {
      margin: 0;
    }
  }
  a {
    color: #8e8e8e;
    text-decoration: none;
    &:hover {
      @include mq(pc) {
        color: #000;
      }
    }
  }
  span {
    &.current {
      color: #000;
    }
  }
  .previouspostslink,
  .nextpostslink {
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-top: 1px solid #8e8e8e;
      border-right: 1px solid #8e8e8e;
      transition: .3s;
    }
    &:hover {
      &:before {
        @include mq(pc) {
          border-color: #000;
        }
      }
    }
  }
  .previouspostslink {
    &:before {
      transform: rotate(-135deg);
    }
  }
  .nextpostslink {
    &:before {
      transform: rotate(45deg);
    }
  }
}
