.btn-normal {
  display: inline-block;
  position: relative;
  width: 386px;
  padding: 18px 45px 18px 20px;
  border: 1px solid $primary;
  text-decoration: none;
  box-sizing: border-box;
  @include mq(sp) {
    display: block;
    width: auto;
    padding: 10px 35px 10px 20px;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 30px;
    width: 7px;
    height: 7px;
    border-top: 1px solid $primary;
    border-right: 1px solid $primary;
    transform: translateY(-50%) rotate(45deg);
    transition: .3s;
    @include mq(sp) {
      right: 20px;
    }
  }
  &:hover {
    @include mq(pc) {
      color: #fff;
      background: $primary;
      &:after {
        border-color: #fff;
      }
    }
  }
}
