.list-01 {
  list-style: none;
  padding: 0;
  & > li {
    position: relative;
    margin: 0 0 5px;
    padding: 0 0 0 25px;
    &:last-child {
      margin: 0;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: #3e3939;
      @include mq(sp) {
        top: 5px;
      }
    }
  }
}

.list-02 {
  list-style: none;
  padding-left: 0;
  & > li {
    counter-increment: cnt;
    position: relative;
    margin: 0 0 5px;
    padding-left: 4em;
    &:last-child {
      margin: 0;
    }
    &:before {
      content: "（" counter(cnt) "）";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
